import React, { useState }  from "react"
import { Link, navigate } from "gatsby"
import { graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import Usa from "@svg-maps/usa.states-territories";
import { SVGMap } from "react-svg-map";

import "../scss/main.scss";

/// Layout
import Header from "../jsx/layouts/nav/Header";
import Footer from "../jsx/layouts/Footer";


export const query = graphql`
  query {
    allLatestScoresJson {
      edges {
        node {
          biodguide
          date
          state
          sentiment
          name
          position
          slug
        }
      }
    }
    allBottomScoresJson {
      edges {
        node {
          abc_score
          bioguide
          name
          num_neg
          num_pos
          pct_pos
          state
          position
          slug
        }
      }
    }
    allTopScoresJson {
      edges {
        node {
          abc_score
          bioguide
          name
          num_neg
          num_pos
          pct_pos
          state
          position
          slug
        }
      }
    }
    allStateScoresJson {
      edges {
        node {
          abc_score
          name
          num_neg
          num_pos
          pct_pos
        }
      }
    }
    allStatesJson {
      edges {
        node {
          slug
          state
          state_name
        }
      }
    }
  }
`

function getLocationName(event) {
	return event.target.attributes.name.value;
}

function get_grade_class(grade) {
  return grade == 'NR' ? 'mini-grade-nr text-white text-center d-inline-block'
       : grade == 'F'  ? 'mini-grade-f text-white text-center d-inline-block'
       : grade == 'D'  ? 'mini-grade-d text-white text-center d-inline-block'
       : grade == 'C'  ? 'mini-grade-c text-white text-center d-inline-block'
       : grade == 'C+' ? 'mini-grade-cp text-white text-center d-inline-block'
       : grade == 'B-' ? 'mini-grade-bm text-white text-center d-inline-block'
       : grade == 'B'  ? 'mini-grade-b text-white text-center d-inline-block'
       : grade == 'B+' ? 'mini-grade-bp text-white text-center d-inline-block'
       : grade == 'A-' ? 'mini-grade-am text-white text-center d-inline-block'
       : grade == 'A'  ? 'mini-grade-a text-white text-center d-inline-block'
       : grade == 'A+' ? 'mini-grade-ap text-white text-center d-inline-block'
       : 'mini-grade-nr text-white text-center d-inline-block';
}

function get_grade_code(grade) {
  return grade == 'NR' ? 'nr'
       : grade == 'F'  ? 'f'
       : grade == 'D'  ? 'd'
       : grade == 'C'  ? 'c'
       : grade == 'C+' ? 'cp'
       : grade == 'B-' ? 'bm'
       : grade == 'B'  ? 'b'
       : grade == 'B+' ? 'bp'
       : grade == 'A-' ? 'am'
       : grade == 'A'  ? 'a'
       : grade == 'A+' ? 'ap'
       : 'nr';
}

export default function Home({ data }) {

  const [locationState, setLocationState] = useState({
    tooltipStyle: {
      display: 'none'
    }
  });

  const [tooltipState, setTooltipState] = useState({
    tooltipStyle: {
      display: 'none'
    }
  });

  const handleLocationMouseOver = (event) =>  {
		const pointedLocation = getLocationName(event);
		setLocationState({ pointedLocation });
	}

	const handleLocationMouseOut = () => {
		setLocationState({ pointedLocation: null });
    setTooltipState({ tooltipStyle: { display: 'none' } });
	}

	const handleLocationMouseMove = (event) => {
		const tooltipStyle = {
			display: 'block',
			top: event.clientY + 10,
			left: event.clientX - 100
		};
		setTooltipState({ tooltipStyle });
	}

  const handleLocationClick = (event) =>  {
		const pointedLocation = getLocationName(event);
    const state_node = data.allStatesJson.edges.filter(edge => edge.node.state_name == pointedLocation)
    navigate(state_node[0].node.slug + '/')
	}

	const getLocationClassName = (location, index) => {
		const state_node = data.allStatesJson.edges.filter(edge => edge.node.state.toLowerCase() == location.id)
    const state_grade = data.allStateScoresJson.edges.filter(edge => edge.node.name == state_node[0].node.state)
		return `svg-map__location svg-map__grade-${get_grade_code(state_grade[0].node.abc_score)}`;
	}

  const getStateScore = (state) => {
    if (state != null) {
      const state_node = data.allStatesJson.edges.filter(edge => edge.node.state_name == state)
      const state_grade = data.allStateScoresJson.edges.filter(edge => edge.node.name == state_node[0].node.state)
      return state_grade[0].node
    } else {
      return ''
    }
	}

  const getStateAbbreviation = (state) => {
    if (state != null) {
      const state_node = data.allStatesJson.edges.filter(edge => edge.node.state_name == state)
      return state_node[0].node.state
    } else {
      return ''
    }
  }

  const getStateScoreClass = (state) => {
    if (state != null) {
      return get_grade_class(getStateScore(state).abc_score)
    } else {
      return ''
    }
	}

  const getStatePctPos = (state) => {
    if (state != null) {
      return ((getStateScore(state).pct_pos) * 100) + '%'
    } else {
      return ''
    }
	}

  const getStatePctNeg = (state) => {
    if (state != null) {
      return ((getStateScore(state).pct_pos > 0) ? (1.0 - getStateScore(state).pct_pos) * 100 + '%' : ((getStateScore(state).num_neg + getStateScore(state).num_pos) > 0 ? '100%' : '0%'))
    } else {
      return ''
    }
	}

  const getPersonScoreClass = (score) => {
    if (score != null) {
      return get_grade_class(score)
    } else {
      return ''
    }
	}
  
  data['custom'] = {}
  
  // console.log(data)
  return (
    <>
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
        <script defer data-domain="electbitcoin.org" src="https://data.electbitcoin.org/js/script.js"></script>
        <title>ElectBitcoin.org - Home</title>
      </Helmet>
      <Header />
      <div className="content-body">
        <div className="container-fluid pt-2">
          <div class="text-center pb-2">
            <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" class="badge badge-info badge-rounded">
              <i class="lab la-twitter scale3"></i> Follow @ElectBitcoinOrg for updates when new records are added!
            </a>
          </div>
          <div className="row">
          	<div className="col-xl-9 col-xxl-9 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-light ">
                    <div className="card-body">
                      <p className="fs-16">
                        <i className="fas fa-person-booth fs-30 scale5 text-dark mb-1 mr-4 float-left"></i>
                        The <Link to="/"><strong className="text-primary">ElectBitcoin.org Project</strong></Link> was created to crowdsource and archive US Politicians' records on <strong>Bitcoin, Cryptocurrencies, and Blockchain Technology</strong>. 
                      </p>
                      <p>
                        According to a recent <a href="https://nydig.com/wp-content/uploads/2021/01/NYDIG-Survey-BitcoinBanking-Jan2021.pdf" target="_blank">NYDIG study</a>, over <strong>46 million</strong> US adults own Bitcoin in 2021. Our mission is to inform this quickly-growing group of voters and empower them to vote for their financial future.
                      </p>
                      <p className="mb-0 fs-14">
                        Learn why we <strong>must</strong> support US innovators in financial technology, how you can get involved, and how our grading system works, on our <Link to="/about/" className="text-primary"><strong>About Page</strong></Link>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-white">
                    <div className="card-body px-1">
                      <div className="card-header border-0 pb-0 pt-0">
                        <h4 className="card-title text-center m-auto">The United States of America</h4>
                      </div>
		      <div class="text-center m-auto fs-12">
		        <p>
			  Click a State to see all its Senators &amp; Representatives
			</p>
		      </div>
                      <div id="usa" style={{ height: "100%" }}>
                        <SVGMap map={Usa} 
                          locationClassName={getLocationClassName}
                          onLocationMouseOver={handleLocationMouseOver}
                          onLocationMouseOut={handleLocationMouseOut}
                          onLocationMouseMove={handleLocationMouseMove}
                          onLocationClick={handleLocationClick}/>
                        <div className="block__map__tooltip rounded" style={tooltipState.tooltipStyle}>
                          {!getStateAbbreviation(locationState.pointedLocation).match(/GU|MP|PR|VI|AS/gi) &&
                            <>
                              <span className={"stateface stateface-replace stateface-" + getStateAbbreviation(locationState.pointedLocation).toLowerCase() + " mr-2"}>{getStateAbbreviation(locationState.pointedLocation)}</span><strong>{locationState.pointedLocation}</strong><span className={getStateScoreClass(locationState.pointedLocation) + ' float-right'}>{getStateScore(locationState.pointedLocation).abc_score}</span>
                            </>
                          }
                          {getStateAbbreviation(locationState.pointedLocation).match(/GU|MP|PR|VI|AS/gi) &&
                            <>
                              <strong>{locationState.pointedLocation}</strong><span className={getStateScoreClass(locationState.pointedLocation) + ' float-right'}>{getStateScore(locationState.pointedLocation).abc_score}</span>
                            </>
                          }
						              <div className="progress mt-3 mb-3" style={{ height: 2 }}>
                            <div
                              className="progress-bar progress-bar-success progress-bar-striped"
                              style={{ width: `${getStatePctPos(locationState.pointedLocation)}`, height: 2 }}
                              role="progressbar"
                            >
                            </div>
                            <div
                              className="progress-bar progress-bar-danger progress-bar-striped"
                              style={{ width: `${getStatePctNeg(locationState.pointedLocation)}`, height: 2 }}
                              role="progressbar"
                            >
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="fs-12 text-success text-center">{getStateScore(locationState.pointedLocation).num_pos} Positive <i className="las la-thumbs-up scale5"></i></span>
                            <span className="fs-12 text-danger text-center">{getStateScore(locationState.pointedLocation).num_neg} Negative <i className="las la-thumbs-down scale5"></i></span>
                          </div>
					              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-xxl-3 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-xl-12 col-sm-6">
                  <div className="card bg-white">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Best & Worst Grades</h4>
                    </div>
                    <div className="card-body pb-0">
                      <div className="basic-list-group">
                        <div className="list-group">
                          {data.allTopScoresJson.edges.map((edge) => (
                            <div key={edge.node.slug + '/'}>
                              <Link to={edge.node.slug + '/'}>
                                <div className="d-flex justify-content-between align-items-center list-group-item px-0 py-0">
                                  <span className="float-left"><strong>{edge.node.name}</strong></span>
                                  <span className={getPersonScoreClass(edge.node.abc_score) + ' float-right'}>{edge.node.abc_score}</span>
                                </div>
                                <div className="pb-3">
                                  <span className="fs-12">{edge.node.state} {edge.node.position}</span>
                                </div>
                              </Link>
                            </div>
                          ))}
                          <div className="row border-top pb-3"></div>
                          {data.allBottomScoresJson.edges.map((edge) => (
                            <div key={edge.node.slug + '/'}>
                              <Link to={edge.node.slug + '/'}>
                                <div className="d-flex justify-content-between align-items-center list-group-item px-0 py-0">
                                  <span className="float-left"><strong>{edge.node.name}</strong></span>
                                  <span className={getPersonScoreClass(edge.node.abc_score) + ' float-right'}>{edge.node.abc_score}</span>
                                </div>
                                <div className="pb-3">
                                  <span className="fs-12">{edge.node.state} {edge.node.position}</span>
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-sm-6">
                  <div className="card bg-white">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Latest Record Entries</h4>
                    </div>
                    <div className="card-body pb-0 d-none d-xl-block">
                      {data.allLatestScoresJson.edges.slice(0,4).map((edge) => (
                        <div key={edge.node.date}>
                          <Link to={edge.node.slug + '/'}>
                            <div className="d-flex justify-content-between align-items-center list-group-item px-0 py-0">
                              <span className="float-left"><strong>{edge.node.name}</strong></span>
                              {edge.node.sentiment == 'positive' &&
                                <span className="text-success text-center">+1 <i className="las la-thumbs-up scale5"></i></span>
                              }
                              {edge.node.sentiment == 'negative' &&
                                <span className="text-danger text-center">-1 <i className="las la-thumbs-down scale5"></i></span>
                              }
                            </div>
                            <div>
                              <span className="fs-12">{edge.node.state} {edge.node.position}</span>
                            </div>
                            <div className="pb-3">
                              <span className="fs-12">{new Date(edge.node.date).toLocaleString()}</span>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                    <div className="card-body pb-0 d-block d-xl-none">
                      {data.allLatestScoresJson.edges.slice(0,7).map((edge) => (
                        <div key={edge.node.date}>
                          <Link to={edge.node.slug + '/'}>
                            <div className="d-flex justify-content-between align-items-center list-group-item px-0 py-0">
                              <span className="float-left"><strong>{edge.node.name}</strong></span>
                              {edge.node.sentiment == 'positive' &&
                                <span className="text-success text-center">+1 <i className="las la-thumbs-up scale5"></i></span>
                              }
                              {edge.node.sentiment == 'negative' &&
                                <span className="text-danger text-center">-1 <i className="las la-thumbs-down scale5"></i></span>
                              }
                            </div>
                            <div>
                              <span className="fs-12">{edge.node.state} {edge.node.position}</span>
                            </div>
                            <div className="pb-3">
                              <span className="fs-12">{new Date(edge.node.date).toLocaleString()}</span>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
